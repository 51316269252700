import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import IFrame from "./components/iframe/iframe";
import Nav from "./components/header/nav";
import Drawing from "./components/drawing/drawing";
import Home from "./Home";
import SignInCallback from "./components/sign-in-callback/sign-in-callback";
import { Amplify, Auth, Hub } from "aws-amplify";

// https://www.thelambdablog.com/using-the-cognito-hosted-ui-for-authentication-with-the-amplify-client-side-library-in-react/
Amplify.configure({
  Auth: {
    region: "ap-southeast-2",
    userPoolId: "ap-southeast-2_nUDKcu9UG",
    userPoolWebClientId: "1bhtlodja0aqb3e1p2jkefpcg3",
    mandatorySignIn: true,
    oauth: {
      domain: "devdengco.auth.ap-southeast-2.amazoncognito.com",
      scope: ["email", "openid", "profile"],
      redirectSignIn: process.env.REACT_APP_SIGNIN_CALLBACK || "",
      redirectSignOut: process.env.REACT_APP_SIGNOUT_CALLBACK || "",
      responseType: "code",
    },
  },
});

function App() {
  console.log(process.env.NODE_ENV);
  console.log(process.env.REACT_APP_SIGNIN_CALLBACK);
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          // signin actions
          Auth.currentSession().then((user) => console.log(user)); // redirect to default page

          break;
        case "cognitoHostedUI":
          console.log("Authenticated...");
          // log the data
          // const idToken = data.signInUserSession.idToken.jwtToken;
          // const accessToken = data.signInUserSession.accessToken;
          // const accessJwtToken = data.signInUserSession.accessToken.jwtToken;
          // const expireIn = data.signInUserSession.accessToken.payload.exp;
          // const refreshToken = data.signInUserSession.refreshToken;
          // // get the user email from the token and save it in the context

          // console.log(idToken);
          // console.log(accessJwtToken);
          // console.log(accessToken);
          // console.log(refreshToken);
          // console.log(`payload data is ${data}`);
          // console.log(token);
          break;
        case "signIn_failure":
          console.log("Error - signIn_failure", data);
          break;
        case "cognitoHostedUI_failure":
          console.log("Error - cognitoHostedUI_failure", data);
          break;
      }
    });
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/drawing" Component={Drawing} />
          <Route path="/dashboard" Component={IFrame} />
          <Route path="/callback" Component={SignInCallback} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
