import { Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const IFrame = () => {
  const [message, setMessage] = useState("");
  // post a message to the iframe

  const iframeRef = useRef<HTMLIFrameElement>(null);
  let targetOrigin = "http://localhost:3000";
  if (process.env.NODE_ENV === "production") {
    targetOrigin = "https://mui-test-app2.dixondeng.com";
  }

  const postMessageToIframe = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      const currentTime = new Date().toLocaleTimeString();
      const message = "Message from outside iframe at " + currentTime;
      iframe.contentWindow.postMessage(message, targetOrigin);
    }
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      console.log("outside iframe received message");
      console.log(event);
      if (
        event.origin !== "http://localhost:3000" &&
        event.origin !== "https://mui-test-app2.dixondeng.com"
      ) {
        // Replace with the origin of the iframe
        return;
      }
      console.log("Message received:", event.data);
      if (typeof event.data === "string") {
        setMessage(event.data);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>
            The following demonstrates an iframe rendering a local html and
            bidirectional communication between the iframe and the hosting react
            component{" "}
          </h3>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={postMessageToIframe}>Post Message to Iframe</Button>
        </Grid>
        <Grid
          item
          xs={6}
          textAlign={"right"}
          alignContent={"centre"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          message from iframe:
        </Grid>
        <Grid
          item
          xs={6}
          textAlign={"left"}
          alignContent={"centre"}
          justifyContent={"center"}
          alignItems={"center"}
          color={"blue"}
        >
          {message}
        </Grid>
      </Grid>
      <iframe
        ref={iframeRef}
        src="/something_local.html"
        title="iframe-test"
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default IFrame;
