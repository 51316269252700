// home page

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const Home = () => {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [currentUserEmail, setCurrentUserEmail] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [jsonData, setJsonData] = useState<any>(null);

  useEffect(() => {
    setIsLoading(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setCurrentUser(user);
        console.log(`currentAuthenticatedUser: ${user.getUsername()}`);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));

    Auth.currentSession()
      .then((session) => {
        console.log(session);
        setCurrentUserEmail(session.getIdToken().payload.email);
      })
      .catch((err) => console.log(err));
  }, []);

  const showNotSignedIn = () => {
    return (
      <div>
        {isLoading && <CircularProgress />}
        {currentUser === null && !isLoading && <h1>You are not signed in</h1>}
      </div>
    );
  };

  // function to get the access token from the current session
  // if the current session is null, return null
  // else return the access token
  const getAccessToken = async () => {
    const session = await Auth.currentSession();
    if (session === null) {
      return null;
    } else {
      return session.getAccessToken().getJwtToken();
    }
  };

  // call GET https://1vsx2gqh9g.execute-api.us-east-1.amazonaws.com/with_auth
  // with Authorization header
  // and log the response
  const getUserDetails = () => {
    getAccessToken().then((token) => {
      if (token !== null) {
        fetch(
          "https://1vsx2gqh9g.execute-api.us-east-1.amazonaws.com/without_auth",
          // "https://property.dixondeng.com",
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data["email"]);
            setJsonData(data);
          })
          .catch((err) => console.log(err));
      }
    });
  };

  // log the user email

  return (
    <div>
      {currentUser === null ? (
        showNotSignedIn()
      ) : (
        <div>
          <h1>Home</h1>
          <h2>Welcome {currentUser.getUsername()}</h2>
          <h2>Your email is {currentUserEmail}</h2>
          {/* MUI button that calls getUserDetails */}
          <Button variant="contained" onClick={getUserDetails}>
            Get User Details
          </Button>
          {jsonData !== null ? (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={6}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Field</TableCell>
                        <TableCell>Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(jsonData).map((field, index) => (
                        <TableRow key={index}>
                          <TableCell>{field}</TableCell>
                          <TableCell>{jsonData[field]}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default Home;
