// react component that takes the authozation code from the url and sends it to the backend

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import Amplify, { Auth } from "aws-amplify";
// import { CognitoJwtVerifier } from "aws-jwt-verify";
// import { CognitoJwtVerifier } from "aws-cognito-jwt-verifier";
// import * as jwt from "jsonwebtoken";

const SignInCallback = () => {
  const location = useLocation();
  console.log(location);

  // define types for the response
  type TokenResponse = {
    accessToken: string;
    idToken: string;
    refreshToken: string;
    expiresIn: number;
    tokenType: string;
    scope: string;
  };

  // define typefor the params
  type Params = {
    grant_type: string;
    client_id: string;
    redirect_uri: string;
    code: string;
  };

  const exchangeCodes = async (code: string) => {
    try {
      // Exchange authorization code for tokens
      const authorizationCode = code;
      console.log(process.env);
      const url = process.env.REACT_APP_TOKEN_ENDPOINT || "";
      const clientId = process.env.REACT_APP_CLIENT_ID || "";
      // const clientSecret = process.env.CLIENT_SECRET_KEY || "";
      const redirectUri = process.env.REACT_APP_REDIRECT_URI || "";
      // const basicString = Buffer.from(
      //   `${clientId}:${clientSecret}`,
      //   "utf8"
      // ).toString("base64");

      let params = {
        grant_type: "authorization_code",
        client_id: clientId,
        redirect_uri: redirectUri,
        code: authorizationCode,
      };
      console.log(url);
      console.log(params);
      const response = await postAuthCode(url, params, "basicString");
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const postAuthCode = async (
    url: string,
    params: Params,
    basicString: string
  ) => {
    try {
      console.log(url);
      console.log(params);

      // send a post request to url with params
      const tokens = await fetch(url, {
        method: "POST",
        headers: {
          // Authorization: `Basic ${basicString}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        //   body: JSON.stringify(params), <-- this didn't work
        body: new URLSearchParams(params),
      })
        .then((response) => {
          console.log(response);
          // cast response to TokenResponse
          return response.json() as Promise<TokenResponse>;
        })
        .then((data) => {
          console.log(data);
          return data;
        });

      return tokens;
      // axios post didn't work
      // const postResponse = await axios.post(url, params, {
      //   headers: {
      //     Authorization: `Basic ${basicString}`,
      //     "Content-Type": "application/x-www-form-urlencoded",
      //   },
      // });
    } catch (error) {
      console.log("request failed");
      console.error(error);
    }
  };

  const getTokens = async () => {
    const url = location.search;
    const code = url.split("=")[1];
    console.log(code);

    // exchange the code for tokens
    const tokens = await exchangeCodes(code);
    console.log(tokens);
    return tokens;
  };

  const createCognitoSession = async () => {
    const tokens = await getTokens();
    console.log(tokens);

    //verify the id token using the cognito jwt verifier

    if (tokens) {
      // try {
      //   const decodedToken = jwt.decode(tokens.idToken);
      //   if (decodedToken && typeof decodedToken === "object") {
      //     const userName = decodedToken["cognito:username"];
      //     console.log("User name:", userName);
      //   } else {
      //     console.log("Invalid ID token");
      //   }
      // } catch (error) {
      //   console.error("Error decoding ID token:", error);
      // }

      const idToken = new AmazonCognitoIdentity.CognitoIdToken({
        IdToken: tokens.idToken,
      });
      console.log(idToken);
      const accessToken = new AmazonCognitoIdentity.CognitoAccessToken({
        AccessToken: tokens.accessToken,
      });
      const refreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
        RefreshToken: tokens.refreshToken,
      });
      const session = new AmazonCognitoIdentity.CognitoUserSession({
        IdToken: idToken,
        AccessToken: accessToken,
        RefreshToken: refreshToken,
      });
      console.log(accessToken.getJwtToken());
      console.log(idToken.payload.email);
      console.log(session);
      // const token = await verifyToken(tokens.idToken, "id");

      const Pool_Data = {
        UserPoolId: "ap-southeast-2_nUDKcu9UG",
        ClientId: "1bhtlodja0aqb3e1p2jkefpcg3",
      };

      const userPool = new AmazonCognitoIdentity.CognitoUserPool(Pool_Data);
      // const userData = {
      //   Username: accessToken.payload.username,
      //   Pool: userPool,
      // };
      // const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      // cognitoUser.setSignInUserSession(session);
      // console.log(cognitoUser);
    }
  };

  useEffect(() => {
    // create a CognitoAccessToken using the response accessToken
    // createCognitoSession();
  }, [location]);

  // See https://medium.com/codex/how-to-process-an-aws-cognito-authorization-code-grant-using-aws-amplify-b49d9ee052ca
  // create a Cognito tokensand ue these tokens to create a CognitoUserSession

  // setup the cognito user pool

  return <div></div>;
};

export default SignInCallback;
