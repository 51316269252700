import { Amplify, Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logoImage from "../../images/ship_white.png";

const Nav = () => {
  const [user, setUser] = useState(null);
  const authorizeUrl =
    "https://devdengco.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize";
  const appClientId = process.env.REACT_APP_CLIENT_ID;
  const signInRedirectUrl = process.env.REACT_APP_SIGNIN_CALLBACK;
  const signInLink = `${authorizeUrl}?client_id=${appClientId}&response_type=code&scope=email+openid+phone&redirect_uri=${signInRedirectUrl}`;

  useEffect(() => {
    const checkUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user);
      } catch (error) {
        console.log(error);
        setUser(null);
      }
    };
    checkUser();
  }, []);

  return (
    // nav with logoImage on the left and links on the right
    <nav className="navbar">
      <div className="logo">
        <img src={logoImage} alt="Logo" className="logo" />
        <p className="navbar-title">ROTR</p>
      </div>
      <ul className="navbar-list">
        <li className="navbar-item">
          <Link to="/">Home</Link>
        </li>
        <li className="navbar-item">
          <Link to="/drawing">Drawing</Link>
        </li>
        <li className="navbar-item">
          <Link to="/dashboard">iframe</Link>
        </li>
        {user === null ? (
          <li className="navbar-item">
            <Link to={signInLink}>Login</Link>
          </li>
        ) : (
          <>
            <li className="navbar-item">
              <Link to="" onClick={() => Auth.signOut()}>
                Logout
              </Link>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Nav;
